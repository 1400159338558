@import '../../node_modules/antd/lib/style/themes/default.less';

// ColorPicker Variables
@white-color: #ffffff;
@black-color: #000000;

//global style variables
@primary-color: #000000;
@primary: #000000;
@secondary-color: #fa8c15;
@text-color: #545454;
@text-color-secondary: #595959;
@heading-color: #535353;
@header-text-color: #262626;
@layout-header-background: #fefefe;
@layout-header-background-dark: #000000;
@layout-footer-background: #fffffe;
@body-background: #f5f5f5;
@nav-bar-bg: #fffffc;
@nav-bar-text-color: @text-color;

@btn-primary-color: #fff;
@btn-primary-bg: @primary-color;

@nav-dark-bg: #003366;
@nav-dark-text-color: #038fdd;
@menu-dark-bg: @nav-dark-bg;
@menu-dark-color: @nav-dark-text-color;
@menu-dark-highlight-color: @secondary-color;
@menu-dark-arrow-color: @nav-dark-text-color;
@hor-nav-text-color: #fffffd;

@border-radius-base: 6px;
@border-radius-sm: 4px;
@border-radius-lg: 10px;

// @themeColor: #414143;
@themeColor: #000;
@themeIconSidebar: #000;
@themeSecondColor: #383938;
@themeLightColor: #e0e0e1;
@themeDarkBoreder: #272727;
@tableBackGroundColor: #f8f8f7;
// faf8f5
@inputLightColor: #ededed;
@leftOptionLightColor: #ced0d0;
@lightCardBg: #eeeeee;
@tableborderColor: #f8f8f7;
@table-box-shadow:0 0 10px 0 rgba(0, 0, 0, 0.05);
@lightthemeBorder: #c2c2c4;
@headerstripcolor: linear-gradient(to bottom, #ecd180 0%, #7e5d58 100%) !important;
// @inputDarkColor: #e1cebb;
@redColor: #db1c1c;
@blueColor: #296cb1;
@greenColor: #469c1a;
@yellowColor: #d8c803;
@cardDrakBorder: #c5cce6;
@headHover: #d7d7d6;
@leftFilterOptionbg: #ced0d0;
@leftOptionLabel: #414143;
@enquiryColor: #6bc950;
@enquiryLightColor: #d7f0d0;
@salesColor: #ffc600;
@salesLightColor: #ffeeb2;
@kycColor: #c95050;
@kycLightColor: #efcccc;
@trSelect: #cdcdcd;
@textDark: #acacac;

.smallScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #fff !important;
}
.smallScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: @themeColor!important;
}
.grayScroll::-webkit-scrollbar-thumb {
  background: #ddd !important;
}
.grayScroll::-webkit-scrollbar {
  background: #000 !important;
}
.smallGrayScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #a1a1a1 !important;
}
.smallGrayScroll::-webkit-scrollbar-thumb {
  background: #6b6b6b !important;
}
.themeGrayScroll {
  &.scrollarea {
    .scrollbar-container {
      background: #a1a1a1 !important;
      width: 5px;
      opacity: 1;
      z-index: 0;
      .scrollbar {
        margin-left: 0 !important;
        background: #6b6b6b !important;
        width: 5px !important;
      }
    }
  }
}
.smallScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #fff !important;
}
.grayScroll::-webkit-scrollbar-thumb {
  background-color: #ddd !important;
}

::-webkit-scrollbar
// .pagination-height .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body::-webkit-scrollbar
{
  width: 8px;
  height: 8px;
  /* This is more usable for users trying to click it. */
  background-color: #ddd !important;
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb
// .pagination-height .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body::-webkit-scrollbar-thumb
{
  background-color: #000000 !important;
  // background-color: #2f3589 !important;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  // background: rgba(0,0,0,0.5);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background-color: #999 !important;
}

.scrollbar-container.horizontal {
  height: 4px !important;
}
.scrollbar-container.horizontal .scrollbar {
  height: 4px !important;
}
