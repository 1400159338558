@import 'variables.less';

.permissionList {
  width: 100%;
}
.permissionLeft {
  width: calc(~'100% - 600px'); //calc(~'100% - 380px');
  h2 {
    margin-bottom: 0 !important;
    // margin-left: 10px;
  }
  img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
}
.permissionAction {
  width: 600px;
  // justify-content: flex-start !important;
  .permissionActionList {
    margin-left: 15px;
    h2 {
      margin-bottom: 0;
      margin-left: 5px;
      text-transform: capitalize;
      font-weight: normal;
      font-size: 12px;
    }
    .ant-checkbox-wrapper {
      .ant-checkbox {
        .ant-checkbox-inner {
          width: 15px !important;
          height: 15px !important;
          border-radius: 2px;
          &:after {
            width: 4px;
            height: 7px;
            // border: 1px solid #fff;
          }
        }
      }
    }
  }
}
.permissionList {
  .customcollapselist {
    padding: 10px 0 10px 10px;
    .accordion__button {
      &:before {
        // position: relative;
        left: -15px;
      }
    }
  }
  &.submenuSingle {
    padding: 10px 0 10px 10px;
  }
}
.permissionWrapper {
  height: calc(~'100vh - 300px');
  overflow: auto;
  padding: 0 10px;
  .menuInnerBlock {
    padding: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .permissionHead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fafafa;
    padding: 10px 15px 10px 10px;
  }
  .collpaseMenuAccess {
    &.permissionList {
      padding: 0 15px;
    }
  }
}
// .caratRangeWrapper {
//   position: relative;
// }
.userPermissionAction {
  padding: 10px;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
  background: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  .commonOutine {
    margin-left: 10px;
  }
}
.userListBlockWrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  .from-group {
    width: calc(~'33.33% - 10px');
    padding: 0 5px;
  }
}
.userInformation {
  padding: 15px;
  // height: calc(~'100vh - 225px');
  background-color: @tableBackGroundColor;
}
.userChangePassword {
  // display: flex;
  // flex-wrap: wrap;
  padding: 15px;
  .input-wrapper {
    .change-password {
      border: none;
      display: block;
      color: rgba(34, 34, 34, 0.6);
      padding: 14px 0;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;
      font-size: 14px;
      line-height: 16px;
    }
    .show-hide-password {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 0.25rem;
      cursor: pointer;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  .newpassword {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 184px;
    left: 420px;
    cursor: pointer;
  }
  .confirmpassword {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 184px;
    right: 138px;
    cursor: pointer;
  }
  .from-group {
    width: calc(~'50% - 10px');
    padding: 0 5px;
  }
}
