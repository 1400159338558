@import 'variables.less';

.editProfilePageInner {
  margin-top: 40px;
  display: flex;
  .profileLeft {
    width: 140px;
    margin: 0 40px 0 0;
    .userProfileRound {
      width: 140px;
      height: 140px;
      border-radius: 100%;
      font-size: 50px;
      background: rgb(64, 188, 134);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-align: center;
      cursor: pointer;
      line-height: 140px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 100%;
      }
    }
  }
  .profileRight {
    width: calc(~'100% - 180px');
    .input-wrapper {
      .change-password {
        border: none;
        display: block;
        color: rgba(34, 34, 34, 0.6);
        padding: 14px 0;
        width: 100%;
        border-bottom: 1px solid #e4e4e4;
        font-size: 14px;
        line-height: 16px;
      }
      .show-hide-password {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 0.25rem;
        cursor: pointer;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
    .currentpassword {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 130px;
      // left: 810px;
      right: 750px;
      cursor: pointer;
    }
    .newpassword {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 206px;
      right: 750px;
      cursor: pointer;
    }
    .confirmpassword {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 280px;
      right: 750px;
      cursor: pointer;
    }
    .profielTwofactor {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e4e4e4;
      padding-bottom: 10px;
      width: 100%;
      .profileTwoFactorBlock {
        margin-left: 15px;
        h2 {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 0;
          margin-right: 10px;
        }
        p {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
  }
}
.color1 {
  border: 1px dashed #dbdbdb;
  background-color: #fff !important;
}
.color2 {
  background-color: rgb(64, 188, 134) !important;
}
.color3 {
  background-color: rgb(26, 188, 156) !important;
}
.color4 {
  background-color: rgb(39, 174, 96) !important;
}
.color5 {
  background-color: rgb(0, 215, 23) !important;
}
.color6 {
  background-color: rgb(243, 29, 47) !important;
}
.color7 {
  background-color: rgb(236, 85, 92) !important;
}
.color8 {
  background-color: rgb(252, 87, 94) !important;
}
.color9 {
  background-color: rgb(252, 180, 16) !important;
}
.color10 {
  background-color: rgb(177, 126, 34) !important;
}
.color11 {
  background-color: rgb(242, 77, 22) !important;
}
.color12 {
  background-color: rgb(255, 134, 0) !important;
}
.color13 {
  background-color: rgb(236, 102, 37) !important;
}
.color14 {
  background-color: rgb(41, 128, 185) !important;
}
.color15 {
  background-color: rgb(52, 152, 219) !important;
}
.color16 {
  background-color: rgb(82, 140, 203) !important;
}
.color17 {
  background-color: rgb(9, 24, 236) !important;
}
.color18 {
  background-color: rgb(25, 158, 199) !important;
}
.color19 {
  background-color: rgb(3, 162, 253) !important;
}
.color20 {
  background-color: rgb(123, 104, 238) !important;
}
.color21 {
  background-color: rgb(7, 67, 84) !important;
}
.color22 {
  background-color: rgb(7, 67, 84) !important;
}
.color23 {
  background-color: rgb(52, 73, 94) !important;
}
.color24 {
  background-color: rgb(24, 29, 33) !important;
}
.colorDropdownWrapper {
  padding: 0 10px 10px !important;
  width: 260px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 6px;
  .profilecolorDropdown {
    .colorTopWrapper {
      display: flex;
      flex-wrap: wrap;
      .colorItem {
        width: 24px;
        height: 24px;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        cursor: pointer;
        transform: scale(0.8);
        transition: all 0.5s ease-in-out;
        &.selected {
          transform: scale(1);
        }
        &:hover {
          transform: scale(1);
        }
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
    .commonButton {
      margin-top: 10px;
      width: 100%;
      padding: 10px 5px;
    }
  }
}
.actionButonBottom {
  margin-top: 15px;
}
