@import './variables.less';

.kycWrapperLightColor {
  background-color: @tableBackGroundColor;
  .kycWrapper {
    position: relative;
    z-index: 1;
    height: 100%;
  }
}
.kycWrapper {
  padding: 15px;
  .kycWrapper-hv-100 {
    // height: 100%;
  }
  .KycCardBlock {
    .companyNameCode {
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        &:first-child {
          font-size: 16px;
          font-weight: 600;
        }
        &:last-child {
          margin-left: 10px;
        }
      }
    }
    .KYCFomrAddress {
      p {
        margin-bottom: 0 !important;
        color: #525252;
      }
    }
  }
  .kycDetailBox {
    align-items: center;
    .kycUploadDoc {
      width: 80px;
      height: 80px;
      border: 1px solid #ddd;
      border-radius: 100%;
      margin-right: 15px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
      }
    }
    .kycDetailItem {
      width: calc(~'100% - 95px');
      .detail {
        display: flex;
        // align-items: center;
        // margin-right: 10px;
        margin-bottom: 10px;
        span {
          img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
          color: #454545;
          font-size: 14px;
          &:first-child {
            margin-right: 8px;
            font-weight: 600;
          }
        }
        // &:last-child{
        //     margin-bottom: 0;
        // }
        p {
          margin-bottom: 0 !important;
          color: #454545;
          line-height: 20px;
        }
      }
    }
    &.addressBlock {
      border-top: 1px solid @themeLightColor;
      padding-top: 10px;
    }
  }
  .KycCardBlock {
    h2 {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
}
.KYCCardBox {
  position: relative;
  .KYCCardBoxTop {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid @themeLightColor;
    padding-bottom: 10px;
    margin-bottom: 5px;
    .cardBoxTitle {
      font-weight: 500;
      font-size: 16px;
    }
    .cardCode {
      font-size: 14px;
      margin-left: 8px;
    }
  }
  .KYCCardBoxDetail {
    display: flex;
    flex-wrap: wrap;
    .detail {
      display: flex;
      margin: 5px 20px 5px 0;
      position: relative;
      flex-wrap: wrap;
      span {
        font-size: 14px;
        color: #000;
        &:first-child {
          font-weight: 500;
          color: #454545;
          margin-right: 5px;
        }
      }
      &:after {
        content: '';
        background: #c9c9c9;
        width: 1px;
        height: 13px;
        position: absolute;
        right: -10px;
        display: block;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}
.mr-20 {
  margin-right: 20px;
}
.KycSettingLeft {
  width: 60%;
}
.KycSettingRight {
  width: 40%;
  border-left: 1px solid #efefef;
  margin-left: 25px;
  padding-left: 15px;
  .searchResultTable {
    max-height: max-content !important;
  }
  .kycSettingRightSticky {
    position: sticky;
    top: 0;
  }
}

.kycFormMainBlock {
  padding: 20px;

  &.additionInfoBlock {
    padding: 0;
    height: calc(~'100vh - 248px');
    .listInnerDeatil {
      height: calc(~'100vh - 342px');
    }
  }
  .additionTopBlock {
    margin-bottom: 10px;

    .AdditionalTwoBlock {
      width: 50%;
      textarea {
        width: 100%;
      }
    }
  }
  .kycBlockWrapper {
    display: flex;
    flex-wrap: wrap;
    .from-group {
      width: 33.33%;
      padding-right: 20px;
    }
    &.twoBlock {
      .from-group {
        width: 50%;
      }
    }
    .addRemoveinputBlock {
      .from-group {
        width: 50%;
      }
    }
  }
}
.kycNewBlockAdd {
  text-align: right;
  margin-bottom: 20px;
  button {
    padding: 5px 14px;
    border-radius: 5px;
  }
}
