@import '../variables.less';

.nav-tab-active-after {
  content: '';
  width: 40%;
  height: 5px;
  background: #000;
  position: absolute;
  bottom: -1rem;
  border-radius: 10px 10px 0 0;
  margin: auto;
  left: 0;
  right: 0;
}

router {
  &-tabs,
  &-routes {
    display: block;
  }
  &-routes {
    overflow: visible !important;
  }
}

.dn-tab-wrapper {
  border-bottom: 1px solid #efefef;

  .scrollarea-content {
    width: max-content;
  }

  &-main {
    // box-shadow: 0 4px 4px #e6e6e6;
    background: @white;
  }

  &.dark {
    background-color: darken(@primary-color, 20%);

    .dn-tab-main {
      .nav-tab {
        color: @white;

        &.active {
          color: @primary-color;
        }
      }
    }
  }

  .dn-tab {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    > * {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .ant-badge {
      margin-top: -2px;
      margin-bottom: -2px;

      > .ant-badge-count {
        background-color: @primary-color;

        > .ant-scroll-number-only {
          > p {
            background-color: @primary-color;
            line-height: 1.25rem;
            color: @white;
          }
        }
      }
    }

    .nav-tab-wrapper {
      position: relative;
    }

    .nav-tab {
      cursor: pointer;
      color: #6e6e6e;

      &.active {
        color: @primary-color;
        font-weight: 600;
      }

      &:hover,
      &:focus-within {
        .nav-tab-dropdown {
          display: flex;
        }
      }
    }

    .nav-tab-dropdown {
      display: none;
      position: fixed;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      z-index: 100000;
      margin: 0 -10px 0 -10px;
      border-radius: 3px;
      box-shadow: @card-shadow;
      background-color: @white;
      padding: 8px;

      &:focus-within {
        display: flex;
      }

      .nav-tab {
        padding: 6px 8px;
        width: 100%;
        list-style: none;
        text-transform: capitalize;
        letter-spacing: 0.5px;
        white-space: nowrap;
        color: #292d34 !important;
        font-size: 14px;
        font-weight: 600;

        &.active {
          border-radius: 3px;
          background-color: @primary-color;
          color: #fff !important;

          &:after {
            display: none !important;
          }
        }
      }
    }
  }

  .dn-tab-main {
    display: flex;
    padding-right: 15px !important;
    padding-left: 15px !important;
    height: 48px;

    .scrollarea {
      width: 300px;

      .scrollarea-content {
        display: flex;
      }
    }

    .mainHeadingTitle {
      font-size: 20px;
      font-weight: 600;
      margin-right: 1rem;
    }

    .headerInfoIcon {
      margin-right: 5px;
      cursor: pointer;
      width: 12px;
      height: 12px;
    }

    .nav-tab {
      position: relative;
      margin: auto 8px;
      list-style: none;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1.5px;
      white-space: nowrap;
      font-size: 12px;

      &.active {
        &:after {
          &:extend(.nav-tab-active-after);
        }
      }
    }
  }

  .dn-tab-sub {
    display: flex;
    padding-right: 10px !important;
    padding-left: 10px !important;
    height: 46px;

    li,
    a {
      color: #545454;
    }

    .dn-tab-sub a {
      position: relative;
      padding: 0 10px 0 10px;
      list-style: none;
    }

    .nav-tab {
      position: relative;
      margin: auto 10px;
      list-style: none;
      text-decoration: none;
      letter-spacing: auto;
      white-space: nowrap;
    }
  }
}
