@import 'variables.less';
.ant-layout.gx-app-layout {
  height: 100vh;
}
.ant-layout-sider {
  background-color: #fffffc;
  color: #818791;
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  width: 280px !important;
  overflow: hidden;
}
.ant-drawer .ant-drawer-wrapper-body,
.ant-drawer .ant-drawer-header {
  background-color: #f8f8f8;
}
.gx-layout-sider-header {
  height: 75px;
  padding: 5px;
}
.gx-layout-sider-header {
  padding: 10px 30px 10px 70px;
  height: 72px;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -ms-align-items: center;
  align-items: center;
}
.gx-layout-sider-header .gx-linebar {
  position: absolute;
  left: 20px;
  z-index: 1;
  top: 15px;
  transition: all 0.3s ease-out;
}
.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.gx-app-layout {
  padding: 0;
  background: #fff !important;
}
.hkSidebarMenu.customTheme.gx-layout-sider-dark {
  background-color: #ffffff !important;
  border-radius: 0 20px 20px 0 !important;
  border-right: 1px solid #e9ebf0;
}
.gx-layout-sider-dark .gx-layout-sider-header {
  background-color: transparent;
  box-shadow: none;
}

.gx-module-box-content {
  box-shadow: none !important;
  padding: 0;
}
.gx-main-content-wrapper {
  background: #fff;
}
.customTheme.hkSidebarMenu .gx-sidebar-content ul li {
  display: block;
  line-height: normal;
  text-align: center;
  cursor: pointer;
  border: none;
  margin: 0 !important;
}
.customTheme.hkSidebarMenu .gx-sidebar-content ul li .vertical-menu,
.customTheme.hkSidebarMenu .gx-sidebar-content ul li .sub-menu-dropdown .vertical-menu {
  color: @themeIconSidebar !important;
  position: relative;
  z-index: 2;
  text-align: center;
  justify-content: center;
  word-break: break-word;
  flex-wrap: wrap;
  white-space: normal;
  font-size: 10px;
  text-transform: uppercase;
}
.customTheme.hkSidebarMenu .ant-menu-inline .ant-menu-submenu {
  padding: 0;
}
.customTheme.hkSidebarMenu .ant-menu-inline > .ant-menu-item a {
  padding: 10px 0px !important;
  text-align: center;
}
.customTheme.hkSidebarMenu .gx-sidebar-content ul li svg,
.customTheme.hkSidebarMenu .gx-sidebar-content ul li .sub-menu-dropdown svg {
  margin: auto;
  fill: @themeIconSidebar;
  stroke: @themeIconSidebar;
  width: 20px;
  height: 20px;
  z-index: 10;
  position: relative;
  display: block;
  margin-bottom: 0px;
  stroke-width: 0.5px;
}
.hkSidebarMenu .gx-sidebar-content ul li .ant-menu-submenu-arrow:before,
.hkSidebarMenu .gx-sidebar-content ul li .ant-menu-submenu-arrow:after {
  background-image: linear-gradient(to right, #555, #555) !important;
  z-index: 2;
}
.customTheme.hkSidebarMenu .gx-sidebar-content ul li .ant-menu-submenu-arrow {
  z-index: 2;
  right: 24px !important;
}
.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-submenu-selected .sub-menu-dropdown svg,
.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-submenu-active .sub-menu-dropdown svg,
.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-item-selected svg,
.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-item-active svg {
  fill: @themeColor;
  stroke: @themeColor;
}
.customTheme.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-submenu-selected .sub-menu-dropdown .vertical-menu,
.customTheme.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-submenu-active .sub-menu-dropdown .vertical-menu,
.customTheme.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-item-selected .vertical-menu,
.customTheme.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-item-active .vertical-menu {
  color: @themeColor !important;
  font-weight: 600;
}
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  display: none;
}
.customTheme.hkSidebarMenu .ant-menu {
  padding-top: 0 !important;
}
.customTheme .gx-layout-sider-scrollbar {
  height: calc(~'100vh - 160px') !important;
}
.customTheme .gx-layout-sider-header .gx-linebar {
  left: 0;
  right: 0;
  margin: auto;
  width: 40px;
  text-align: center;
}
.gx-layout-sider-header img {
  width: 100%;
  margin: auto;
}
.customTheme.gx-header-horizontal-dark .gx-header-horizontal-nav {
  display: none !important;
}
.customTheme.hkSidebarMenu .ant-menu-submenu > .ant-menu {
  background-color: #fff !important;
  border-radius: 0;
  margin: 0 15px 15px 15px;
  width: auto;
  border-radius: 10px;
  padding: 0 20px !important;
}
.customTheme.hkSidebarMenu .ant-menu-submenu.ant-menu-submenu-selected > .ant-menu {
  margin: 20px 15px 20px 15px;
}
.customTheme.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
.customTheme .ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 0 !important;
}
.customTheme.hkSidebarMenu .ant-menu-item:active,
.customTheme .ant-menu-submenu-title:active {
  background: transparent;
}
.userProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 10px 5px;
  border-top: 1px solid @themeLightColor;
}
.userProfile .userProfileLeft {
  width: 30px;
  height: 30px;
  border-radius: 100% !important;
}
.userProfile .userProfileLeft img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
  border-radius: 100% !important;
}
.userProfileText {
  color: #222;
  font-size: 14px;
  margin-bottom: 5px;
}
.userProfileName {
  color: #000;
  font-weight: bold;
}
// .customTheme li.ant-menu-item.ant-menu-item-selected a:after,
// .hkSidebarMenu .ant-menu-inline > .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title:after {
//     content: "";
//     width: 95%;
//     height: 100%;
//     position: absolute;
//     background: #fff;
//     border-radius:25px 0 0 25px;
//     display: block;
//     right: 0;
//     z-index: 1;
//     top: 0;
// }
.customTheme .ant-menu-sub li.ant-menu-item.ant-menu-item-selected a:after {
  content: none;
}
.customTheme.hkSidebarMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a {
  color: @themeColor !important;
}
.hkSidebarMenu .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: auto !important;
  line-height: normal !important;
  padding: 12px 5px !important;
  position: relative;
}

.hkSidebarMenu .ant-menu-inline > .ant-menu-item a {
  height: auto !important;
  display: block;
  line-height: normal !important;
}
.dashboard-table {
  background: #fff !important;
}
.gx-layout-sider-dark .gx-icon-btn:focus,
.gx-layout-sider-dark .gx-icon-btn:hover {
  background-color: transparent !important;
}
.customTheme.hkSidebarMenu .ant-menu-item > a,
.customTheme.hkSidebarMenu .ant-menu {
  color: #000;
}
.gx-layout-sider-scrollbar > div {
  overflow-x: hidden !important;
  width: 100%;
}
.gx-layout-sider-scrollbar > div::-webkit-scrollbar {
  background-color: transparent !important;
  width: 2px;
}
.gx-module-box-header {
  margin-bottom: 10px;
}
.customTheme .ant-layout-sider-children {
  display: grid;
  grid-template-rows: 1fr auto;
  will-change: transform, width;
}

.profilePopupOver .ant-popover-inner {
  border: 1px solid #efefef;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
}
.profilePopupOver {
  left: 8px !important;
  width: 190px;
  .ant-popover-inner-content {
    padding: 0;
  }
}
.profileMenuHover {
  padding: 0;
  margin: 0;
  .profileMenuProfile {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 14px;
    color: #000;
    padding: 10px 15px;
    margin-bottom: 0;
    .userProfileLeft {
      margin-right: 10px;
      img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
      }
    }
  }
  li {
    padding: 7px 15px !important;
    list-style: none;
    a {
      font-size: 13px;
      color: #777777;
      &:hover {
        color: @themeColor;
      }
    }
    &:hover {
      background-color: transparent !important;
    }
  }
  .userProfileHr {
    border-bottom: 1px solid #e9ebf0;
    width: 85%;
    margin: 10px auto;
  }
  .socialLinkMenuPopup {
    margin-top: 10px;
    background-color: #fafbfc;
    border-top: 1px solid #e9ebf0;
    display: flex;
    padding: 8px 10px;
    justify-content: space-between;
    align-items: center;
    .socialLinkTitle {
      font-size: 12px;
      text-transform: uppercase;
      color: #9aa2bc;
    }
    .socialLinkMenu {
      img {
        width: 14px;
        height: 14px;
        margin-left: 5px;
      }
    }
  }
}
.hideShowSidebar {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  position: fixed;
  top: 39px;
  left: 69px;
  display: flex;
  border: 1px solid #e9ebf0;
  z-index: 10;
  background: #fff;
  cursor: pointer;
  img {
    margin: auto;
    width: 10px;
    height: 10px;
  }
  &.active {
    left: 40px;
  }
}

.sideBarScroll {
  height: calc(~'100vh - 183px');
}
.shortCutPopup {
  left: 75px !important;
  // box-shadow: 0 1px 10px 0 rgba(0,0,0,.25);
  // background: #fff;
  // border-radius: 6px;
  // padding: 15px;
  .ant-dropdown-menu {
    top: 74px;
    position: relative;
  }
}
.shortCutMenu {
  min-width: 120px;
  h2 {
    font-size: 18px;
    font-weight: 600;
    padding: 5px 10px;
    margin: 0;
  }
  li {
    font-size: 14px;
    color: #555;
    letter-spacing: 0.5px;
    svg {
      fill: #555;
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
    &:hover {
      background-color: transparent;
      color: @themeColor;
      svg {
        fill: @themeColor;
      }
    }
  }
}
