@import '../../styles/variables.less';

.ant-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.commonModal {
  width: 900px !important;
  padding-bottom: 0 !important;
  top: 0;
  &.md-size {
    width: auto !important;
    min-width: 700px !important;
    max-width: 80%;
  }
  &.sm-size {
    width: 700px !important;
  }
  &.xs-size {
    width: 400px !important;
  }
  .ant-modal-content {
    border-radius: 8px;
    padding: 25px;
    .ant-modal-close-x {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .ant-modal-header {
      border: none;
      text-align: center;
      .ant-modal-title {
        text-align: center;
        color: #343434;
        font-size: 24px;
        font-weight: 600;
      }
    }
    .ant-modal-footer {
      border-top: none;
    }
    .commonModalButton {
      display: flex;
      justify-content: center;
      button {
        min-width: 120px;
        padding: 10px 15px;
        font-size: 16px;
        border-radius: 5px;
        letter-spacing: 0.5px;
        cursor: pointer;
        transition: 0.2s all;
        &.fillButton {
          background-color: @themeColor;
          color: #fff;
          border: 1px solid @themeColor;
          &:hover {
            background-color: #fff;
            border: 1px solid @themeColor;
            color: @themeColor;
          }
        }
        &.outLineButton {
          background-color: #fff;
          border: 1px solid @themeColor;
          color: @themeColor;
          &:hover {
            background-color: @themeColor;
            color: #fff;
          }
        }
      }
    }
  }
}
.offerDetailsCell.commonModal {
  width: 1020px !important;
}

@primary-color: #000000;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #000000;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;