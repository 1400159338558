.drawer-calc {
  &-footer {
    display: flex;
    margin: 0 -10px 0 -10px;
    width: 100%;
  }

  &-column {
    padding: 0 10px 0 10px;
    width: 33.33%;
  }

  &-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px 0 5px 0;

    span {
      margin: 0;
      padding: 0 5px 0 5px;
      text-transform: capitalize;
      color: #000;

      &:first-child {
        margin-right: -5px;
        margin-left: -5px;
      }

      &:last-child {
        color: #000;
        font-weight: 800;
      }
    }
  }
}

.drawer-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  padding-top: 1rem;
  flex-wrap: wrap;
  overflow: auto;

  .form-group {
    padding: 0 5px 10px 5px;
    width: 33.3333%;
  }
}

.dn-table-form {
  .ant-drawer-body {
    height: 100%;

    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-content: space-between;
    }

    .drawer-form,
    .searchResultTable {
      max-height: calc(~'50vh - 65px');
    }
  }
}
