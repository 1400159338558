.ErrorBoundary {
  display: flex;
  flex-flow: column;
  padding: 0.5rem;
  height: 100%;

  &Alert {
    margin-top: auto;
    margin-bottom: auto;
  }

  &Header,
  &ButtonWrapper {
    text-align: center;
  }

  &Error {
    padding: 0.5rem;
    width: 100%;
    overflow: auto;
    color: red;
  }
}

@primary-color: #000000;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #000000;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;