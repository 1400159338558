@import 'variables.less';

.configurationInnerSubTb {
  height: 100%;
  max-height: 100%;
}
.settingMainWrapper {
  // width: 100vw;
  // height: 100vw;
  // position: fixed;
  // left: 0;
  // right: 0;
  // top: 0;
  // z-index: 100;
  // background-color: #fff;
  // display: flex;
  // flex-wrap: wrap;
  .settingLeftScroll {
    height: 100vh;
    .scrollarea-content {
      height: 100%;
    }
    .scrollbar-container {
      opacity: 0;
    }
  }
  .settingLeftBlock {
    background-color: @themeLightColor;
    padding: 40px;
    width: 250px;
    height: 100%;
    overflow: auto;
    a {
      &:hover {
        color: #acacac;
      }
    }
    .seetingMainTitle {
      color: #424242;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 1px;
      margin: 20px 0;
    }
    ul {
      padding: 0;
      li {
        list-style: none;
        a {
          margin: 5px 0;
          padding: 10px 0;
          display: block;
          color: @textDark;
          font-size: 16px;
          &.active {
            color: #000;
            font-weight: 600;
          }
        }
      }
    }
    .settingSpereter {
      width: 100%;
      height: 1px;
      background-color: rgba(192, 158, 124, 0.26);
      margin: 20px 0;
    }
    .settingSubTitle {
      font-weight: 600;
      letter-spacing: 1px;
      color: @themeColor;
      text-transform: uppercase;
      font-size: 15px;
      margin-bottom: 20px;
    }
  }
  .settingRightBlock {
    width: calc(~'100% - 250px');
    // padding: 10px 20px;
    // .settingRightInneer {
    //   // padding: 20px 10px;
    //   // overflow: auto;
    //   // max-height: 100vh;
    // }
  }
}
.quickSearchTable .settingLeftScroll .content {
  overflow: auto;
}
.headeTitle {
  h2 {
    color: #424242;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0 0 20px 0;
  }
}
.commonDec {
  p {
    color: #aeacb0;
    font-size: 14px;
    a {
      color: @themeColor;
      text-decoration: underline;
      font-weight: 600;
    }
  }
}

.settingSecondTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.submasterTab {
  .leftArrow {
    margin-left: 15px;
  }
}
.subMasterTable {
  padding-left: 25px;
}
.lastEditUser {
  .lastEditUserName {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .userName {
    font-size: 12px;
  }
  .infoIcon {
    margin-left: 5px;
    img {
      width: 10px;
      height: 10px;
    }
  }
}
.subMasterHeader {
  padding: 10px;
  background: @white;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.09);
}
// .configurationWrapper {
//   .homeSettingWrapper {
//     width: 30%;
//     .commonButton-download {
//       background-color: #000 !important;
//       color: #fff;
//     }
//     .mtb-10 {
//       margin: 10px 0px;
//       .ant-select-selection {
//         border-bottom: 1px solid #e4e4e4 !important;
//         border-top: 0px solid transparent;
//         border-left: 0px solid transparent;
//         border-right: 0px solid transparent;
//         border-radius: 0px;
//       }
//     }
//   }
// }
.heading-upload {
  color: #424242;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 0 20px 0;
}
.uploadPriceListButton {
  background-color: #000 !important;
  border: none !important;
  border-radius: 10px !important;
  color: #fff !important;
  display: block !important;
  margin-top: 10px !important;
}
.disableUpload {
  background-color: #e0e0e1 !important;
  color: #acacac !important;
}
.upload-wrapper {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: column;
  cursor: pointer;
  img {
    width: 40px;
  }
  .title {
    margin-bottom: 0;
    margin-top: 10px;
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }
  .discription {
    color: #999;
    line-height: 24px;
  }
}
.file-delete-wrapper {
  gap: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  p {
    margin-bottom: 0 !important;
  }
  img {
    width: 17px;
    cursor: pointer;
  }
}
.homeSettingWrapper {
  .from-group {
    width: 50%;
    padding-right: 10px;
  }
  &.mt-20 {
    &.scroll-y-auto {
      height: calc(100vh - 14px);
      // height: calc(100% - 90px);
      overflow: auto;
    }
  }
}
.imageBlockWrapper {
  width: 50px;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
}
.homeSettingfooter {
  width: calc(~'100% - 250px');
  left: 250px;
  bottom: 0;
}
.uploadDiamonds {
  .select-file {
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
    input[type='file'] {
      width: 100%;
      height: 100%;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: 0;
      cursor: pointer;
      left: 0;
      top: 0;
    }
    .upload_images_content {
      padding: 12px;
      text-align: center;
      img {
        width: 40px;
      }
      .title {
        margin-bottom: 0;
        margin-top: 10px;
        font-weight: 600;
        color: #000;
        font-size: 16px;
      }
      .desc {
        margin-bottom: 0;
      }
    }
  }
  .rdoOption {
    .ant-radio-group {
      display: block;
    }
    .rdoBtn {
      border: 1px solid #e9e9e9;
      padding: 10px 15px;
      border-radius: 10px;
      display: flex;
      .subTitle {
        margin-bottom: 0;
      }
      & + .rdoBtn {
        margin-top: 10px;
      }
      .desc {
        margin-bottom: 0;
      }
    }
  }
}
