@import 'variables.less';

.searchResultListWrapper {
  &.offerTable {
    height: calc(~'100vh - 102px');
    position: relative;
  }
}
.userDetailEdit {
  margin-left: 5px;
  margin-right: 10px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
}
.filterMainDropdown {
  .ant-dropdown-menu {
    min-width: 320px;
    display: flex;
    flex-wrap: wrap;
    max-width: 320px;
    li {
      background-color: @tableBackGroundColor;
      padding: 2px 8px;
      border-radius: 20px;
      margin: 3px;
      span {
        color: @themeColor;
        font-size: 12px;
      }
      .filterClose {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background-color: @themeColor;
        display: flex;
        margin-left: 5px;
        img {
          width: 6px;
          height: 6px;
          margin: auto;
          display: block;
        }
      }
    }
  }
}
.salesDiscountPage {
  .tabInnerTableScroll {
    height: calc(~'100vh - 89px');
  }
}
.popupHalfShowbottom {
  &.grid_height {
    .searchResultTable {
      height: calc(~'70vh - 215px') !important;
    }
  }
}
.exportExcelPopUp {
  &.grid_height {
    .searchResultTable {
      height: calc(~'90vh - 115px') !important;
    }
  }
}
.transactionEnquiry {
  .searchResultTable {
    table {
      thead {
        tr {
          th {
            z-index: 1;
          }
        }
      }
    }
  }
}
.searchResultTable {
  height: calc(~'100vh - 192px');
  overflow: auto;
  &.tabInnerTableScroll {
    height: calc(~'100vh - 104px');
  }
  &.enquireyPageScroll {
    height: calc(~'100vh - 248px');
  }
  &.configrautionScroll {
    height: calc(~'100vh - 89px');
  }
  &.usersccessScroll {
    height: calc(~'100vh - 127px');
  }
  &.cartPage {
    height: calc(~'100vh - 153px');
  }
  &.consignmentScroll {
    height: calc(~'100vh - 191px');
  }
  &.enquiryInnerSubScroll {
    height: calc(~'100vh - 224px');
  }
  &.notePageScroll {
    height: calc(~'100vh - 82px');
  }
  &.enquiryWithoutFilter {
    height: calc(~'100vh - 174px');
  }
  &.checkImageTable {
    height: calc(~'100vh - 140px');
  }
  &.clientInnerSubScroll {
    height: calc(~'100vh - 269px');
  }
  &.clientActionTable {
    height: calc(~'100vh - 86px');
  }
  &.clientSalesTra {
    height: calc(~'100vh - 213px');
  }
  &.diamondListinSetColunm {
    padding-left: 30px;
    tr {
      td:first-child,
      th:first-child {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
      }
    }
  }
  table {
    width: 100%;
    position: relative;
    thead {
      tr {
        th {
          // text-transform: uppercase;
          color: #000;
          font-size: 14px;
          font-weight: 600;
          padding: 5px 3px;
          letter-spacing: 0.5px;
          text-align: center;
          position: sticky;
          top: -10px;
          z-index: 100;
          cursor: pointer;
          background: @tableBackGroundColor;
          white-space: nowrap;
          .selectAmount {
            color: #000 !important;
            font-weight: bold;
            font-size: 10px;
            height: 12px;
            display: block;
          }
        }
      }
    }
    tbody {
      .tableSortingTd {
        position: absolute;
        left: -20px;
        margin: 10px 0;
        cursor: move;
        img {
          width: 16px;
          height: 16px;
        }
      }
      tr {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid @tableBackGroundColor;
        position: relative;
        vertical-align: top;

        &.IGI {
          td {
            background: #ffffff08;
            box-shadow: none;
          }
        }
        &.HRD {
          td {
            background: #e9e9e908;
            box-shadow: none;
          }
        }
        &.FM {
          td {
            background: #f1f1f108;
            box-shadow: none;
          }
        }
        &.OTHER {
          td {
            background: #ededed08;
            box-shadow: none;
          }
        }

        &:hover:not(.table-collapse-row) {
          background: #dddddd;

          td {
            background: #dadada;
          }
        }

        td {
          cursor: pointer;
          text-align: center;
          padding: 8px 8px;
          white-space: nowrap;
          border-right: 1px solid #f2f2f2;
          vertical-align: middle;

          a {
            color: inherit;
          }

          &:first-child {
            width: 120px;
          }
        }
        &.selectTr {
          td {
            background: @trSelect;
            border-right: 1px solid @trSelect;
          }
        }
        // &:nth-child(1){
        //     .SelectcolorWiseList{
        //         &.red{
        //             display: block;
        //         }
        //     }
        // }
        // &:nth-child(2){
        //     .SelectcolorWiseList{
        //         &.green{
        //             display: block;
        //         }
        //         &.red{
        //             display: none !important;
        //         }
        //     }
        // }
        // &:nth-child(3){
        //     .SelectcolorWiseList{
        //         &.blue{
        //             display: block;
        //         }
        //         &.red{
        //             display: none !important;
        //         }
        //     }
        // }
      }
      &.tableNewRowAdd {
        tr {
          border: 1px solid @themeColor;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
          td {
            position: relative;
            z-index: 1;
            background: #fff;
            input {
              border: none;
              text-align: center;
            }
            .SelectcolorWiseList {
              display: block;
            }
          }
        }
      }
    }
  }
  .tableHeaderMain {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
    border-radius: 4px;
    padding: 3px 5px;
    white-space: nowrap;
    width: auto;
    background: @tableBackGroundColor;
    font-size: 14px;
    color: #000;
    letter-spacing: 0.5px;
    font-weight: 600;
    position: relative;

    .tableListIcon {
      margin: 0 1rem;
      cursor: pointer;

      svg {
        fill: '#414141';
        width: 14px;
        height: 14px;
      }
    }

    .listIcon {
      // margin-right: 1px;
      // opacity: 0;
      // visibility: hidden;
      // width: 12px;
      // height: 12px;
      position: absolute;
      left: -12px;
      z-index: 2;
      margin-right: 1px;
      opacity: 0;
      visibility: hidden;
      width: 12px;
      height: 12px;
      cursor: move;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .shortingRound {
      position: absolute;
      right: -14px;
      z-index: 2;
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: #ababab;
      display: flex;
      opacity: 0;
      align-items: center;
      margin-left: 5px;
      justify-content: center;
      visibility: hidden;
      .shortingRoundInner {
        height: 10px;
        display: block;
        img {
          width: 8px;
          height: 5px;
          display: block;
          margin: auto;
        }
      }
    }
    .selectShortingMainBlock {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      z-index: 2;
      .selectShorting {
        background-color: @themeColor;
        padding: 3px 7px;
        border-radius: 13px;
        display: flex;
        line-height: 12px;
        margin-left: 2px;
        .selectShortingCount {
          font-size: 10px;
          margin-right: 5px;
          color: #fff;
        }
        .selectShortingArrow {
          width: 8px;
          height: 8px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .shortingClose {
        width: 16px;
        height: 16px;
        background-color: #bcc0c7;
        border-radius: 100%;
        display: flex;
        opacity: 0;
        visibility: hidden;
        margin-left: 3px;
        position: absolute;
        right: -22px;
        img {
          margin: auto;
          width: 6px;
          height: 6px;
        }
      }
    }
  }
  th {
    .thead-filtered {
      text-decoration: underline;
    }

    &:hover {
      z-index: 1000;
      .tableHeaderMain {
        &.shortingBoxShow {
          &:after {
            width: calc(~'100% + 17px + 24px');
            right: -19px;
          }
        }
        &.squeceChange {
          &::after {
            width: calc(~'100% + 6px + 6px');
            left: -6px;
            right: -6px;
          }
        }
        &.shortingApply {
          &:after {
            width: calc(100% + 23px + 18px);
          }
        }
        &:after {
          content: '';
          width: calc(~'100% + 17px + 6px');
          left: -17px;
          position: absolute;
          height: 100%;
          border-radius: 4px;
          right: -6px;
          background: @headHover;
        }
        .taleHeadTitle {
          z-index: 2;
        }
        .listIcon {
          opacity: 1;
          visibility: visible;
        }
        .shortingRound {
          opacity: 1;
          visibility: visible;
        }
        .selectShortingMainBlock {
          .shortingClose {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  .imageSliderFullBlock {
    // width: 200px;
    margin: 5px;
    display: grid;
    .magnifier {
      height: 210px !important;
      max-width: 215px;
    }
    img {
      margin: 0 auto;
      height: 100%;
      max-height: 210px;
      object-fit: contain;
    }
    .downlaod_data {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: flex-end;
      border-top: 1px solid #ddd;
      padding: 15px;
      text-align: center;
      .dwn_liks {
        display: flex;
        justify-content: center;
        a {
          width: 30px;
          height: 30px;
          border: 1px solid #ddd;
          border-radius: 100%;
          display: flex;
          margin: 0 5px;
          img {
            width: 16px;
            height: 16px;
            margin: auto;
          }
        }
      }
    }
    &.height-auto img {
      height: auto !important;
    }
  }
  .grp_video {
    display: flex;
    flex-direction: column;
    position: relative;
    .imageSliderFullBlock {
      margin: 0 5px 0;
      img {
        max-height: 105px;
        object-fit: contain;
      }
    }
    .grp_name {
      position: absolute;
      bottom: 67px;
      left: 5px;
      width: calc(~'100% - 10px');
      text-align: center;
      background: #000;
      color: #fff;
      font-size: 12px;
      line-height: 17px;
    }
  }
}

.tabelDropdownClass {
  .menuInnerFilterOption {
    width: 200px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    border-radius: 6px;
    .menuInnerFilterInner {
      padding: 10px;
      max-height: calc(~'100vh - 350px');
      overflow: auto;
      margin-top: 4px;
      &::-webkit-scrollbar {
        // display: none;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(186, 186, 192, 0.5) !important;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      .llSortingTitle {
        font-size: 12px;
        text-transform: uppercase;
        color: #858585;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
      .llSortingTitleList {
        .llSortingTitleItem {
          display: flex;
          position: relative;
          margin: 10px 0;
          align-items: center;
          cursor: pointer;
          .selectSortingTitle {
            background: @themeLightColor;
            border-radius: 5px;
            padding: 3px 5px;
            color: @themeColor;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 14px;
          }
          .sqvanceImage {
            width: 14px;
            height: 14px;
            display: block;
            margin-right: 5px;
            cursor: move;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
          .shortingRoundInner {
            width: 10px;
            height: 14px;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
            margin: auto;
            img {
              width: 10px;
              height: 8px;
              display: block;
            }
          }
        }
      }
      .llfilterMenuDropdown {
        border-top: 1px solid #e9ebf0;
        ul {
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          .llSortingTitle {
            width: 100%;
            margin-bottom: 5px;
          }
          li {
            list-style: none;
            padding: 4px 10px;
            font-size: 14px;
            text-transform: uppercase;
            cursor: pointer;
            border: 1px solid #e9ebf0;
            border-radius: 4px;
            margin: 2px;
            &.active {
              background-color: @themeColor;
              color: #fff;
              border: 1px solid @themeColor;
            }
          }
        }
        .llfilterSelect {
          margin: 0 0 10px 0;
          padding: 5px 0;
          border-bottom: 1px solid #e9ebf0;
          li {
            background-color: @themeColor;
            color: #fff;
            border: 1px solid @themeColor;
            position: relative;
            .closeFilter {
              width: 12px;
              height: 12px;
              right: -4px;
              top: -6px;
              border-radius: 100%;
              background-color: @themeLightColor;
              position: absolute;
              display: flex;
              cursor: pointer;
              img {
                width: 6px;
                margin: auto;
                height: 6px;
              }
            }
          }
        }
      }
    }
  }
}
.amountCalalateOverClass {
  width: 200px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 6px;
  padding: 10px;
  .calculatorTitle {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .calculatorSelect {
    width: 100%;
  }
  button {
    border: none;
    cursor: pointer;
    background: @themeColor;
    width: 100%;
    color: #fff;
    border-radius: 6px;
    padding: 7px 10px;
    display: block;
    text-align: center;
    margin-top: 10px;
  }
  .calTotalAmount {
    border-top: 1px solid #e9ebf0;
    color: #82868d;
    font-size: 14px;
    margin: 10px -10px 0 -10px;
    padding: 8px 5px 0 5px;
    text-align: center;
  }
}
.amountCalalateOverClass.active {
  display: block !important;
}
.SelectcolorWiseList {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 2px;
  background: #6bc950;
  display: inline-block;
  &.red {
    background-color: #db1c1c;
    display: block;
  }
  &.blue {
    background-color: #307bea;
  }
}
.ant-popover-content {
  .ant-popover-inner-content {
    padding: 6px;
    .showSatusBlock {
      display: flex;
      align-items: center;
      width: auto;
      background: #f1f1f1;
      border: 1px solid #ddd;
      font-weight: 600;
      padding: 4px;
      margin: 2px;
      color: #545454;
      border-radius: 4px;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      &:hover {
        border-color: #c09e7c;
        color: #c09e7c;
        background-color: #f1eae2;
      }
      span {
        min-width: 20px;
        text-align: left;
      }
    }
  }
}
.showSatusBlock {
  display: flex;
  align-items: center;
  span {
    max-width: 125px !important;
    width: 125px;
    word-break: break-word;
    overflow-wrap: break-word;
    display: inline-block;
    white-space: break-spaces;
    text-align: left;
  }
}
.selectActionIcon {
  display: flex;
  align-items: center;
  position: relative;
  .selectActionIconWrapper {
    width: calc(~'100% - 0px');
    display: flex;
    justify-content: center;
    margin-left: 5px;
    &.pairStkNo {
      position: absolute;
      right: -20px;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: @themeColor;
        border-color: @themeColor;
      }
    }
    .ant-checkbox-inner {
      width: 16px;
      height: 16px;
      &:after {
        width: 5px;
        height: 8px;
        background-color: @themeColor;
      }
    }
  }
}
.tableGropupingBox {
  position: absolute;
  left: 0;
  width: 100%;
  height: 20px;
  // margin-top: 20px;
  // margin-top: 13px;
  .tableGroupTitle {
    display: inline-flex;
    position: sticky;
    white-space: nowrap;
    bottom: 0;
    left: 0px;
    // left: -10px;
    z-index: 10;
    .tableCollapseBox {
      width: 14px;
      margin-right: 10px;
      height: 14px;
      display: flex;
      background-color: #fff;
      position: relative;
      border-radius: 2px;
      cursor: pointer;
      img {
        margin: auto;
        width: 8px;
        height: 8px;
      }
      &:after {
        content: '';
        width: 1px;
        height: 22px;
        background: @themeDarkBoreder;
        position: absolute;
        right: -6px;
        top: -4px;
      }
    }
    .tableGroupTitleInner {
      color: #fff;
      padding: 1px 5px;
      line-height: 22px;
      border-radius: 4px 4px 0 0;
      white-space: nowrap;
      background: @themeColor;
      margin-right: 5px;
      display: flex;
      align-items: center;
      .selectActionIconWrapper {
        margin-left: 0;
      }
      .customCheckBoxLebel {
        background-color: #fff;
        &:after {
          border: 2px solid @themeColor;
          border-top: 0;
          border-left: 0;
        }
      }
      .groupClose {
        margin-left: 5px;
        margin-top: -3px;
        cursor: pointer;
        img {
          width: 8px;
          height: 8px;
        }
      }
      &.collpaseBlock {
        width: auto;
        // padding: 0;
        span {
          display: none;
        }
        .groupClose {
          margin-left: 0;
        }
      }
      span {
        font-size: 11px;
      }
      .moreOption {
        width: 10px;
        height: 10px;
        display: block;
        cursor: pointer;
        margin-left: 10px;
        display: none;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      &.moreOptionShow {
        .moreOption {
          display: block;
        }
      }
      &:hover,
      &.moreOptionShow {
        .moreOption {
          display: block;
        }
      }
      .checkBoxSelect {
        display: block;
        margin-right: 10px;
        position: relative;
        cursor: pointer;
        img {
          width: 12px;
          height: 14px;
        }
        &:after {
          content: '';
          width: 1px;
          height: 22px;
          background: @themeDarkBoreder;
          position: absolute;
          right: -6px;
          top: -4px;
        }
      }
    }
  }
}
.groupingTableAmount {
  text-align: center;
  padding-top: 15px !important;
  color: #000;
  font-weight: bold;
  font-size: 13px;
  height: 12px;
  cursor: pointer;
  background-color: @tableBackGroundColor;
  border: none !important;

  &:first-child {
    text-align: right;
    // padding: 15px 15px 8px 8px;
  }
  &.clacOffTr {
    text-align: left;
  }
  .selectAmount {
    position: relative;
    z-index: 1;
  }
}
.inputHover {
  width: 80px;
  padding: 2px 5px;
  border-radius: 5px;
  display: block;
  border: 1px solid #fff;
  &:hover {
    border: 1px solid #e2e2e2;
  }
}
.tableInput {
  width: 80px;
  margin: auto;
  position: relative;
  input {
    width: 100%;
    padding: 2px 5px;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    font-size: 12px;
  }
  &.xlSize {
    input {
      padding: 4px 5px;
    }
  }
  &.tableTopOfferInput {
    input {
      font-size: 13px;
      position: absolute;
      top: -16px;
      left: 0;
      padding: 4px 5px;
    }
  }
  .inputEditDelet {
    position: absolute;
    top: 0;
    right: 5px;
    height: 25px;
    img {
      width: 10px;
      height: 10px;
      margin: 0 2px;
      &.close {
        width: 8px;
        height: 8px;
        margin-left: 2px;
      }
    }
  }
  select {
    border: 1px solid #e2e2e2;
    border-radius: 5px;
  }
}
.tableLink {
  color: #000;
}
.rapPrice {
  color: #db1c1c;
}
.stockStatus {
  .ant-select-selection {
    width: 200px;
  }
}
.askingPrice {
  color: #469c1a;
}
.m2mPrice {
  color: #296cb1;
}
.certificateNo {
  color: @themeColor;
  text-decoration: underline;
  &:hover {
    color: @themeColor;
    text-decoration: underline;
  }
}
.diamondDetailSummary {
  .tabInnerTableScroll {
    height: calc(~'100vh - 174px');
  }
}
.colorDropdown {
  background: #fff;
  box-shadow: 0 0 14px 0px rgba(0, 0, 0, 10%);
  padding: 5px 10px;
  border-radius: 5px;
  .SelectcolorWiseList {
    display: block;
    width: 12px;
    height: 12px;
    cursor: pointer;
    margin: 5px 0;
  }
}
.addNewColumButton {
  color: rgba(52, 52, 52, 0.5);
  white-space: nowrap;
  padding: 5px 8px;
  border-radius: 5px;
  margin-top: 5px;
  display: flex;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  position: sticky;
  left: 0;
  &:hover {
    background: #e2e7ed;
  }
  img {
    width: 10px;
    margin-right: 5px;
    height: 10px;
  }
}
.filedLeftBlock {
  position: relative;
}
.closeRow {
  position: absolute;
  left: 0;
  img {
    width: 10px;
    margin-left: 5px;
    height: 10px;
  }
}
.colunmAlign {
  display: flex;
  justify-content: center;
  .colunmAlignItem {
    width: 30px;
    img {
      width: 16px;
      height: 16px;
    }
  }
}
.diamondListingAction {
  justify-content: space-between;
}
.listingPagePagination {
  display: flex;
  align-items: center;
  .clientPagination {
    display: flex;
    align-items: center;
    ul {
      margin-bottom: 0;
      li {
        line-height: 20px;
        &.active {
          background-color: @themeColor;
          color: #fff;
        }
      }
    }
    .paginationText {
      color: #7a7a7a;
      font-size: 12px;
      margin-left: 5px;
    }
    .paginationSelect {
      .ant-select-selection {
        background: #fff;
        border-radius: 5px;
        border: 1px solid #ddd;
        height: auto;
        .ant-select-selection__rendered {
          line-height: 26px;
          .ant-select-selection-selected-value {
            font-size: 12px;
          }
        }
        .ant-select-arrow {
          svg {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}
.normalTableDropdown {
  .tableDropdown {
    position: absolute;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    border-radius: 6px;
    padding: 6px 20px;
    display: none;
    z-index: 2;
    span {
      display: block;
      text-transform: uppercase;
      font-size: 14px;
      margin: 0 2px;
      border-bottom: none !important;
    }
  }
  &:hover {
    .tableDropdown {
      display: flex;
    }
  }
}
.numberValue {
  // text-align: right;
  display: block;
}
td[role='cell'] .numberValue {
  text-align: center !important;
  display: inline-block;
}
.redColor {
  color: #db1c1c;
  font-weight: 400;
}
.blueColor {
  color: #4121e6;
  font-weight: 400;
}
.greenColor {
  color: #6bc950 !important;
  font-weight: 400;
}
.selectStoneValueBlock {
  border: 1px solid #efefef;
  padding: 2px 90px 2px 20px;
  max-height: 40px;
  min-height: 40px;
  background-color: #f8f8f8;
  position: absolute;
  top: -40px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .selectStoneValueItem {
    display: flex;
    margin-right: 10px;
    align-items: center;
    span {
      &.selectStoneLabel {
        color: rgba(52, 52, 52, 0.5);
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        margin-right: 7px;
        display: block;
      }
      &.selectStopnValue {
        color: #307bea;
      }
    }
  }
  .copyButton {
    color: #fff;
    position: absolute;
    right: 15px;
    cursor: pointer;
    padding: 3px 8px;
    border-radius: 5px;
    // top: 6px;
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
    display: block;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-right: 65px;
    height: 24px;

    img {
      width: 20px;
      height: 20px;
    }
  }
  .copyButton1 {
    color: #fff;
    position: absolute;
    right: 40px;
    cursor: pointer;
    padding: 3px 8px;
    border-radius: 5px;
    // top: 6px;
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
    display: block;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-right: 65px;
    height: 24px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .searchStoneClose {
    color: #fff;
    position: absolute;
    right: 15px;
    cursor: pointer;
    background: @themeColor;
    padding: 5px 8px;
    border-radius: 5px;
    top: 6px;
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
    display: block;
    cursor: pointer;
    img {
      width: 10px;
      height: 10px;
    }
  }
}
.selectStoneValueBlock2 {
  border: 1px solid #efefef;
  padding: 2px 90px 2px 20px;
  max-height: 40px;
  min-height: 40px;
  background-color: #f8f8f8;
  position: absolute;
  top: 40px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .selectStoneValueItem {
    display: flex;
    margin-right: 10px;
    align-items: center;
    span {
      &.selectStoneLabel {
        color: rgba(52, 52, 52, 0.5);
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        margin-right: 7px;
        display: block;
      }
      &.selectStopnValue {
        color: #307bea;
      }
    }
  }
  .copyButton {
    color: #fff;
    position: absolute;
    right: 15px;
    cursor: pointer;
    padding: 3px 8px;
    border-radius: 5px;
    // top: 6px;
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
    display: block;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-right: 65px;
    height: 24px;

    img {
      width: 20px;
      height: 20px;
    }
  }
  .copyButton1 {
    color: #fff;
    position: absolute;
    right: 40px;
    cursor: pointer;
    padding: 3px 8px;
    border-radius: 5px;
    // top: 6px;
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
    display: block;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-right: 65px;
    height: 24px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .searchStoneClose {
    color: #fff;
    position: absolute;
    right: 15px;
    cursor: pointer;
    background: @themeColor;
    padding: 5px 8px;
    border-radius: 5px;
    top: 6px;
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
    display: block;
    cursor: pointer;
    img {
      width: 10px;
      height: 10px;
    }
  }
}

.FilterOption {
  margin-right: 10px;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.offerPrice {
  margin: 0 2px;
  &.client {
    color: red;
  }
  &.admin {
    color: green;
  }
}
.popupInnerTitle {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 15px 0;
}
.confirmListOption {
  display: flex;
  flex-wrap: wrap;
  .from-group {
    width: 25%;
    padding-right: 10px;
  }
}
.summaryListWrapper {
  margin-bottom: 20px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 20px;
  overflow: auto;
  .summaryListTop {
    display: flex;
    background: @tableBackGroundColor;
    padding: 6px;
    margin-bottom: 5px;
    justify-content: space-between;
    span {
      width: calc(100% / 10px);
      color: #343434;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
    }
  }
  .summaryListScroll {
    max-height: 200px;
    overflow: auto;
    .summayListBottom {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 13px;
        text-align: center;
        width: calc(100% / 10px);
        margin: 5px 0;
      }
    }
  }
  &.commentTable {
    table {
      tr {
        td {
          vertical-align: top;
        }
      }
    }
  }
  table {
    width: 100%;
    tr {
      th,
      td {
        padding: 5px 8px;
      }
      th {
        // display: flex;
        background: @tableBackGroundColor;
        margin-bottom: 5px;
        justify-content: space-between;
      }
      td {
        .tableInputValue {
          input {
            width: 80px;
            height: 24px;
            border: 1px solid #ddd;
            border-radius: 5px;
          }
        }
        border-right: 1px solid #f2f2f2;
      }
    }
  }
}
.tableHeaderMain {
  .taleHeadTitle {
    .note-space {
      white-space: break-spaces;
    }
  }
}
.tableInputValue {
  input {
    width: 100%;
    height: 26px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  &.noteAdd {
    width: 120px;
    display: block;
    input {
      width: 100%;
    }
  }
}
.DiamondDetailPopup {
  &.oneLineTotal {
    display: block;
    column-count: 3;
  }
  .DiamondDetailPopupItem {
    white-space: nowrap;
    width: 50%;
    display: flex;
    margin-bottom: 10px;
    span {
      color: #000;
      width: 50%;
      &:first-child {
        text-transform: uppercase;
        color: #333;
        font-size: 11px;
        margin-right: 10px;
        min-width: 140px;
        font-weight: 500;
      }
      &:last-child {
        font-weight: 600;
        color: #000;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }
}
.checkImageBox {
  img {
    width: 16px;
    height: 16px;
    &.checkClose {
      width: 12px;
      height: 12px;
    }
  }
}
// .transactionDetailPopup{
//     table{
//         tr{
//             td,th{
//                 padding: 5px 6px !important;
//             }
//         }
//     }
// }
.consignMentRight {
  width: calc(~'100% - 360px');
  position: relative;
  &.active {
    width: calc(~'100% - 25px');
  }
}
.singleStonPriceConfig {
  table {
    tr {
      &:first-child {
        th:nth-child(1),
        th:nth-child(2),
        th:nth-child(3),
        th:nth-child(4) {
          .tableHeaderMain {
            opacity: 0;
          }
        }
      }
      td {
        background-color: #fff;
        border-right: none !important;
        position: relative;
        &:before {
          content: '';
          width: 1px;
          height: 102%;
          background: #f2f2f2;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
        }
        &:nth-child(4) {
          &:after {
            content: '';
            width: 2px;
            height: 102%;
            background: @themeColor;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }
        &:nth-child(odd) {
          &:before {
            background-color: #c1d3e8;
          }
        }
      }
      td,
      th {
        &:first-child {
          position: sticky;
          left: -11px;
          width: 124px;
          z-index: 1000;
          &:before {
            background-color: #f2f2f2;
          }
        }
        &:nth-child(3) {
          position: sticky;
          left: 124px - 11px;
          width: 124px;
          z-index: 1000;
          &:before {
            background-color: #f2f2f2;
          }
        }
        &:nth-child(3) {
          position: sticky;
          left: 124px + 109px - 11px;
          width: 85px;
          z-index: 1000;
        }
        &:nth-child(4) {
          position: sticky;
          left: 124px + 109px + 85px - 11px;
          width: 82px;
          z-index: 1000;
        }
      }
      th {
        background-color: @tableBackGroundColor;
      }
    }
  }
}
.tableInputValue {
  input {
    width: 100%;
    height: 26px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  &.noteAdd {
    width: 120px;
    input {
      width: 100%;
    }
  }
}
.orderDetailBlock {
  padding: 10px 0;
  .from-group {
    width: 50%;
    padding: 0 10px;
    margin-bottom: 10px;
  }
}
.tableListIconMain {
  .tableListIcon {
    padding: 0 5px 0 12px;
    position: relative;
    cursor: pointer;
    img {
      width: 12px;
      height: 12px;
    }
    &:after {
      content: '';
      width: 1px;
      height: 20px;
      background: @themeDarkBoreder;
      position: absolute;
      left: 4px;
      top: -4px;
    }
  }
}
.holdPopupBlock {
  display: flex;
  flex-wrap: wrap;
  max-height: 60vh;
  overflow: auto;
  .from-group {
    width: 50%;
    padding-right: 10px;
  }
}
.oneLineTotal {
  // margin-top: 20px;

  &.add-notes {
    &.mb-10px {
      margin-bottom: 20px;
    }
    .DiamondDetailPopup {
      display: -ms-flexbox;
      display: flex;
    }
  }
  .mainHeadingTitle {
    white-space: nowrap;
    .DiamondDetailPopup {
      display: -ms-flexbox;
      display: flex;
    }
  }
  .DiamondDetailPopupItem {
    margin-right: 5px;
    align-items: center;
    padding-right: 5px;
    margin-bottom: 0;
    width: auto;
    span {
      &:first-child {
        min-width: max-content;
        font-weight: 400;
      }
    }
    &:last-child {
      border-right: none;
      margin-right: 0;
    }
  }
}
.autoSetValue {
  .ant-select-arrow,
  .ant-select-selection__clear {
    display: none;
  }
}
.addedCarat {
  padding: 3px 5px;
  margin: 5px;
  border: 1px solid @themeColor;
  background-color: @themeLightColor !important;
  color: @themeColor!important;
  svg {
    fill: @themeColor;
  }
  &.default_r {
    background-color: #c9c9c9 !important;
  }
}
.singleStonePlaceOrderDetail {
  display: flex;
  flex-wrap: wrap;
  .DiamondDetailPopuplist {
    display: block;
    column-count: 3;
  }
  .from-group {
    width: calc(100% / 3 - 0px);
    padding-right: 10px;
  }
}

.aproove-drawer {
  &.drawer-calc-footer {
    width: 100%;
    .drawer-calc-column {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      .w-50 {
        width: 50%;
      }
      .drawer-calc-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-right: 30px;
        // width: calc(50% - 10px);
        .w-49 {
          width: 49%;
          .text-right {
            text-align: right;
          }
        }
      }
    }
  }
}
.timerCell {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .time {
    width: 100%;
    font-size: 12px;
    color: #000;
    font-weight: 600;
  }
  .date {
    font-size: 12px;
    color: #000;
    font-weight: 600;
    padding-top: 5px;
    width: 100%;
  }
  .countDownBlock {
    display: flex;
    justify-content: space-between;
    .countdownItem {
      &:not(:last-child) {
        padding-right: 5px;
      }
      span {
        display: block;
        &:first-child {
          font-size: 12px;
          color: #000;
          font-weight: 600;
        }
        &:last-child {
          text-transform: uppercase;
          color: #555;
          font-size: 10px;
          font-weight: 500;
        }
      }
    }
  }
}
.trial-hide {
  position: absolute;
  bottom: 0;
  height: 12px;
  width: 64px;
  background: #fff;
  left: 0;
}
.priorityTitle {
  text-transform: capitalize !important;
}
.width-full {
  width: 100%;
}
.detailShow {
  align-items: normal;
  .detail {
    margin: 5px 5px 5px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: inherit;
    width: calc(~'100% / 7 - 10px');
    vertical-align: top;
    span {
      width: 100%;
      text-align: center;
      display: block;
    }

    span {
      &:first-child {
        font-weight: 600;
        height: 50%;
        vertical-align: top;
      }
    }
  }
}
span.verticalHeader {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.offerPageNoPad tbody td {
  padding: 5px 2px !important;
}
.listLabel {
  color: #000;
  font-weight: bold;
  margin-right: 5px;
  display: block;
  white-space: nowrap;
}
.ant-modal {
  &.single_stone_popup {
    top: auto !important;
  }
}
.listTagsMaster {
  display: flex;
  flex-wrap: wrap;

  .listTagList {
    align-items: center;
    display: flex;
  }
}
.searchOrderRequest {
  img {
    padding: 2px;
  }
}
.add-contain {
  border-bottom: 3px solid #414143 !important;
  position: absolute;
}
.similarStoneLabel {
  position: relative;
  // content: "Similar Stones";
  top: 37px;
  // top: 48px;
  left: 405px;
  background: white;
  padding: 0px 7px;
  font-size: 13px;
  font-weight: 600;
  display: none;
}
.searchResultTable tr td:first-child .similarStoneLabel {
  display: block;
}
.diamondGridView {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  .diamondGridViewItem {
    width: calc(~'25% - 10px');
    margin: 5px;
    border: 1px solid #e2e2e2;
    // padding: 10px;
    border-radius: 10px;

    .DiamondGridViewImg {
      width: 100%;
      height: 250px;
      background: #fafafa;
      border-radius: 10px 10px 0 0;
      position: relative;

      .no-msg {
        min-height: 100%;
        width: 100%;
        font-size: 18px;
        color: #000;
        text-align: center;
        display: flex !important;
        justify-content: center;
        align-items: center;
        font-weight: 600;
      }

      .diamondGrdiCheckbox {
        position: absolute;
        left: 10px;
        top: 10px;
      }
      .noImageGrid {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px 10px 0 0;
      }
    }
    .gridViewContent {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #e2e2e2;
      padding-top: 10px;
      /* margin-top: 15px; */
      padding: 10px;
      .gridLeftContent {
        width: 60%;
        text-align: left;
        h2 {
          font-size: 16px;
          font-weight: 800;
          margin-bottom: 5px;
          line-height: 20px;
        }
        a {
          margin-bottom: 5px;
          color: #000;
          display: block;
          font-weight: 500;
          text-decoration: underline;
        }
        p {
          margin: 0;
          font-size: 14px;
          color: #545454;
        }
      }
      .gridRightContent {
        width: 40%;
        text-align: right;
        .labNm {
          color: rgb(0, 140, 186);
        }
        span {
          display: block;
          color: #000;
          font-weight: 500;
          font-size: 16px;
        }
        .noneSpan {
          display: block;
          color: #000;
          font-size: 14px;
        }
        .statusShow {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 14px;
          margin-top: 5px;
          .statusIcon {
            margin-left: 5px;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            display: block;
            background: #6bc950;
            &.red {
              background-color: #db1c1c;
            }
            &.blue {
              background-color: #307bea;
            }
          }
        }
      }
    }
  }
  &.two_col {
    .diamondGridViewItem {
      width: calc(~'50% - 10px');
      display: flex;
      border: 2px solid #bbbbbb;
      .DiamondGridViewImg {
        width: 40%;
        border-radius: 10px 0 0px 10px;
        img {
          border-radius: 10px 0 0px 10px;
        }
      }
      .gridViewContent {
        border-top: 0;
        width: 60%;
        .gridLeftContent {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding-left: 10px;
          p {
            width: 50%;
            margin: 0;
            .p_val {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
